import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import Spinner from './views/spinner/Spinner';
import './assets/scss/style.scss';

import './i18n/i18n';

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./app')), 2000);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
