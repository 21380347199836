import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import properties from "../lang/properties.json";

i18n
  // Detecting user language
  .use(LanguageDetector)
  // Passing i18n instance to react-i18next
  .use(initReactI18next)
  // Initializing i18next
  .init({
    debug: true,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },

    resources: {
      es: {
        translation: {
          Login: {
            login_button: "Ingresar",
            reset_button_password: "Recuperar contraseña",
          },
          common: {
            logout: "Salir",
          },
        },
        properties: properties,
      },
      en: {
        translation: {
          Login: {
            login_button: "Login",
          },
          common: {
            logout: "Logout",
          },
        },
      },
    },
  });

export default i18n;
